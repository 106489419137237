@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800italic,800,700italic,700,600italic,600,400italic,300italic,300);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300);
@import "~hamburgers/dist/hamburgers.css";

@font-face {
    font-family: 'fontello';
    src: url("fonts/fontello.eot?66460098");
    src: url("fonts/fontello.eot?66460098#iefix") format("embedded-opentype"), url("fonts/fontello.woff?66460098") format("woff"), url("fonts/fontello.ttf?66460098") format("truetype"), url("fonts/fontello.svg?66460098#fontello") format("svg");
    font-weight: normal;
    font-style: normal;
}

@import "~font-awesome/css/font-awesome.css";
@import "~rfs/scss/_rfs";

$shelves-width: 960px;
$shelves-columns: 12;
$shelves-gutter: 0px;
$shelves-margin: 0px;
$shelves-mobile-breakpoint: 650px;
$shelves-tablet-breakpoint: 1169px;
$shelves-tablet-prefixes: true;
$shelves-nested-columns: true;
@import "compass/reset";
@import "compass/css3";
@import "shelves-grid";
@import "mixin";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
$opensans: "Open Sans",
sans-serif;
$opensans-light: "Open Sans Condensed",
sans-serif;
$azul_claro: #00afef;
$azul_escuro: #252642;
$cinza_textos: #666;
$green: #02af9a;
$blank: #fff;
$cinza_bordas: #d7d7d7;

.slider-nav .slick-slide.slick-current.slick-active {
    border-color: $azul_claro !important;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    background-size: 100% auto;
    height: 100%;
}

body * {
    @include box-sizing;
    font-family: $opensans;
    -webkit-appearance: none;
}

input[type="search"]::-ms-clear {
    /* removes close icon - IE */
    display: none;
}

input,
a {
    outline: none !important;
}

body {
    -webkit-overflow-scrolling: touch;
    font-size: 1em;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%; //background:url(../images/bgalpha.jpg) no-repeat center top;
}

a {
    text-decoration: none;
    @include transition1s;
}

a {
    outline: 0 !important;
    outline: none !important;

    &:focus,
    &:active,
    &:hover,
    &:focus {
        outline: 0 !important;
        outline: none !important;
    }
}

*:focus {
    outline: 0 !important;
    outline: none !important;
}

::focus {
    outline: none !important;
}

::-moz-selection {
    background: #00b4cd;
    color: $blank;
    text-shadow: none;
}

::selection {
    background: #00b4cd;
    color: $blank;
    text-shadow: none;
}

.clear {
    clear: both;
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body.overflow-hidden {
    overflow: hidden;
}

.cd-main-header {
    position: relative;
    background: transparent url(../images/bg_header.png) repeat-y center top;
    height: auto;
    width: 100%;
    z-index: 99;

    .blank_header {
        background: $blank;
    }
}

.cd-main-content {
    border-top: 1px solid $azul_claro;
}

.logo_atlas {
    background: url(../images/logo.png) no-repeat 0 0;
    width: 176px;
    height: 42px;
    margin: 27px auto 0;
    display: block;
    @include hide-text;

    a {
        display: block;
        height: 42px;
    }
}

@media only screen and (max-width: $shelves-tablet-breakpoint) {
    .row_banner {
        width: 100%;
        margin: 0 auto;
    }

    .cd-main-header {
        position: relative;
        background: $azul_escuro;
        height: auto;
        width: 100%;
        z-index: 3;

        .blank_header {
            background: none;
        }
    }

    .cd-main-content {
        border-top: 1px solid $azul_claro;
    }

    .logo_atlas {
        background: url(../images/logo.png) no-repeat center center;
        width: 176px;
        height: 60px;
        margin: 0 auto;
        display: block;
        @include hide-text;

        a {
            display: block;
            height: 42px;
        }

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            margin: 0 0 0 20px;
        }
    }
}

@media only screen and (min-width: $shelves-tablet-breakpoint) {
    .row_banner {
        width: 1280px;
        margin: 0 auto;
    }

    .cd-nav {
        margin: 25px 0 20px auto;
        overflow: hidden;
        float: right;

        ul {
            li {
                display: inline-block;
                position: relative;
                margin: 0px;
                padding-right: 20px;

                &::after {
                    content: '|';
                    position: absolute;
                    right: 7px;
                    font-size: 11px;
                    color: $azul_claro;
                    top: 1px;
                }

                &:last-child {
                    padding-right: 0px;

                    &::after {
                        display: none;
                    }
                }

                a {
                    color: #000;
                    font-size: 14px;
                    text-transform: uppercase;
                    @include transition2s;

                    &:hover,
                    &.active {
                        color: $azul_claro;
                    }
                }
            }
        }
    }

    .contRight {
        float: right;

        .menu_language {
            overflow: hidden;
            display: block;
            height: 31px;
            float: left;
            padding: 5px 15px 0;

            ul {
                li {
                    display: inline-block;
                    position: relative;
                    margin: 0px;
                    padding-right: 22px;

                    &::after {
                        content: '\b7\a0';
                        position: absolute;
                        right: 0px;
                        font-size: 26px;
                        color: $azul_claro;
                        top: 0px;
                        line-height: 20px;
                    }

                    &:last-child {
                        padding-right: 0px;

                        &::after {
                            display: none;
                        }
                    }

                    a {
                        color: #000;
                        font-size: 10px;
                        text-transform: uppercase;
                        @include transition2s;

                        &:hover,
                        &.active {
                            color: $azul_claro;
                        }
                    }
                }
            }
        }

        .menu_apoio {
            background: #ebebeb;
            display: block;
            height: 31px;
            float: left;
            padding: 5px 15px 0;
            position: relative;

            ul {
                overflow: hidden;

                li {
                    display: inline-block;
                    position: relative;
                    margin: 0px;
                    padding-right: 22px;

                    &::after {
                        content: '|';
                        position: absolute;
                        right: 6px;
                        font-size: 10px;
                        color: #000;
                        top: 4px;
                    }

                    &:last-child {
                        padding-right: 0px;

                        &::after {
                            display: none;
                        }
                    }

                    a {
                        color: #000;
                        font-size: 10px;
                        text-transform: uppercase;
                        @include transition2s;

                        &:hover,
                        &.active {
                            color: $azul_claro;
                        }
                    }

                    .lguser {
                        color: $azul_escuro;
                        font-size: 10px;
                        display: inline-block;
                        position: relative;
                        padding: 0 0 0 20px;
                        text-transform: uppercase;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            left: 0px;
                            content: ' ';
                            top: 0px;
                            width: 11px;
                            height: 10px;
                            background: url(../images/ico_login.png) no-repeat 0 0;
                        }
                    }
                }
            }
        }

        .box_busca {
            border: 1px solid #ebebeb;
            display: block;
            height: 31px;
            float: left;
            position: relative;

            form {
                float: left;
                position: relative;
            }

            input {
                display: block;

                &[type='text'] {
                    background: $blank;
                    border: none;
                    font-size: 12px;
                    color: #000;
                    padding: 5px;
                    text-align: center;
                    width: 195px;
                    float: left;
                    outline: none !important;
                }

                &[type='submit'],
                &[type='buttom'] {
                    border: none;
                    width: 32px;
                    height: 30px;
                    background: $azul_escuro url(../images/ico_lupa.png) no-repeat center center;
                    position: relative;
                    @include hide-text;
                    cursor: pointer;
                    float: left;
                    outline: none !important;
                    @include transition2s;

                    &:hover {
                        background-color: $azul_claro;
                    }
                }
            }
        }
    }
}

div.autocomplete {
    position: absolute;
    border: 1px solid #ebebeb;
    border-bottom: 2px solid $azul_claro;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: default;
    display: none;
    z-index: 1001;
    background-color: $blank;
    min-width: 100%;
    overflow: auto;
    top: 30px;
    padding: 5px 0;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        top: 40px;
    }

    div.listValues {
        background: $blank;
        white-space: nowrap;
        cursor: pointer;
        line-height: 16px;
        padding: 5px 8px;
        font-size: 12px;
        color: $cinza_textos;

        @media only screen and (max-width: $shelves-tablet-breakpoint) {
            font-size: 14px;
        }

        b {
            font-weight: 700;
            color: $azul_claro;
        }

        >div {
            @include transition1s;

            @media only screen and (max-width: $shelves-tablet-breakpoint) {
                line-height: 30px;
            }
        }
    }

    div.listValues>div:hover {
        background: #f6f6f6;
    }

    .produtosCpl {
        padding: 0px 8px;

        h5 {
            font-size: 14px;
            color: $azul_escuro;
            text-transform: uppercase;
            border-top: 0px solid #b7b7b7;
            padding: 8px 0 0;
        }

        .ProdListCpl {
            li {
                display: block;
                border-bottom: 1px solid #b7b7b7;
                padding: 8px 0;

                a {
                    display: block;
                    overflow: hidden;
                    @include transition1s;

                    figure {
                        display: inline-block;
                        float: left;
                        padding-right: 10px;
                        overflow: hidden;

                        img {
                            display: block;

                            @media only screen and (max-width: $shelves-mobile-breakpoint) {
                                width: 50px;
                            }
                        }
                    }

                    span {
                        display: block;

                        h6 {
                            font-size: 14px;
                            color: $azul_claro;
                            padding: 5px 0;
                            display: block;
                        }

                        small {
                            font-size: 12px;
                            color: $cinza_textos;
                            padding: 0px;
                            display: block;
                            font-weight: 400;
                            white-space: normal;
                        }
                    }

                    &:hover {
                        background: #f6f6f6;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $shelves-tablet-breakpoint) {
    .contRight {
        float: none;

        .menu_language {
            overflow: hidden;
            display: block;
            height: 31px;
            float: left;
            padding: 5px 10px 0;
            width: 40%;
            background: $blank;

            ul {
                text-align: center;

                li {
                    display: inline-block;
                    position: relative;
                    margin: 0px;
                    padding-right: 22px;

                    &::after {
                        content: '\b7\a0';
                        position: absolute;
                        right: 0px;
                        font-size: 26px;
                        color: $azul_claro;
                        top: 0px;
                        line-height: 20px;
                    }

                    &:last-child {
                        padding-right: 0px;

                        &::after {
                            display: none;
                        }
                    }

                    a {
                        color: #000;
                        font-size: 10px;
                        text-transform: uppercase;
                        @include transition2s;

                        &:hover,
                        &.active {
                            color: $azul_claro;
                        }
                    }
                }
            }
        }

        .menu_apoio {
            background: #ebebeb;
            display: block;
            height: 31px;
            float: left;
            padding: 5px 10px 0;
            width: 60%;
            position: relative;

            ul {
                text-align: center;
                overflow: hidden;

                li {
                    display: inline-block;
                    position: relative;
                    margin: 0px;
                    padding-right: 22px;

                    &::after {
                        content: '|';
                        position: absolute;
                        right: 6px;
                        font-size: 10px;
                        color: #000;
                        top: 4px;
                    }

                    &:last-child {
                        padding-right: 0px;

                        &::after {
                            display: none;
                        }
                    }

                    a {
                        color: #000;
                        font-size: 10px;
                        text-transform: uppercase;
                        @include transition2s;

                        &:hover,
                        &.active {
                            color: $azul_claro;
                        }
                    }

                    .lguser {
                        color: $azul_escuro;
                        font-size: 10px;
                        display: inline-block;
                        position: relative;
                        padding: 0 0 0 20px;
                        text-transform: uppercase;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            left: 0px;
                            content: ' ';
                            top: 0px;
                            width: 11px;
                            height: 10px;
                            background: url(../images/ico_login.png) no-repeat 0 0;
                        }
                    }
                }
            }
        }

        .box_busca {
            border: 1px solid #ebebeb;
            display: block;
            height: 31px;
            float: none;
            clear: both;
            border-bottom: 1px solid $azul_claro;
            background: $blank;
            position: relative;

            form {
                overflow: hidden;
            }

            input {
                display: block;

                &[type='text'],
                input[type="search"] {
                    background: $blank;
                    border: none;
                    font-size: 12px;
                    color: #000;
                    padding: 5px;
                    text-align: center;
                    width: 90%;
                    float: left;

                    @media only screen and (max-width: $shelves-mobile-breakpoint) {
                        text-align: left;
                        text-indent: 15px;
                    }
                }

                &[type='submit'],
                &[type='buttom'] {
                    border: none;
                    width: 10%;
                    height: 30px;
                    background: $azul_escuro url(../images/ico_lupa.png) no-repeat center center;
                    position: relative;
                    @include hide-text;
                    cursor: pointer;
                    float: left;
                }
            }
        }
    }
}

.btn-acessar {
    background: #252642;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 60px;
    float: right;
    position: relative;
    margin: 30px 0px;
}

@media only screen and (max-width: $shelves-mobile-breakpoint) {
    .banner .rocket-flicker-arrow {
        display: none;
    }
    .banner .rocket-flicker ul.flicks {
        height: 140px;
    }

    .contRight {
        float: none;

        .menu_language {
            height: auto;
            padding: 8px 20px;
            width: 100%;
            float: none;

            ul {
                text-align: right;

                li {
                    padding-right: 22px;

                    a {
                        font-size: 12px;
                    }
                }
            }
        }

        .menu_apoio {
            height: auto;
            padding: 8px 10px;
            width: 100%;
            float: none;
            position: relative;

            ul {
                text-align: left;
                overflow: hidden;

                li {
                    padding-right: 15px;

                    &::after {
                        right: 3px;
                    }

                    a {
                        font-size: 12px;
                    }

                    .lguser {
                        color: $azul_escuro;
                        font-size: 12px;
                        display: inline-block;
                        position: relative;
                        padding: 0 0 0 15px;
                        text-transform: uppercase;
                        font-weight: 600;

                        &::after {
                            position: absolute;
                            left: 0px;
                            content: ' ';
                            top: 0px;
                            width: 11px;
                            height: 10px;
                            background: url(../images/ico_login.png) no-repeat 0 0;
                        }
                    }
                }
            }
        }

        .box_busca {
            height: auto;
            background-color: $blank;

            input {
                display: block;

                &[type='text'],
                input[type="search"] {
                    font-size: 14px;
                    line-height: 35px;
                    width: 80%;
                    height: 40px;
                }

                &[type='submit'],
                &[type='buttom'] {
                    height: 40px;
                    width: 20%;
                }
            }
        }
    }
}

div.meu_cadastro,
div.esqueci_senha {
    position: absolute;
    border: none;
    box-shadow: 0 2px 4px rgba(164, 217, 236, 0.2);
    -webkit-box-shadow: 0 2px 4px rgba(164, 217, 236, 0.2);
    cursor: default;
    display: none;
    z-index: 2;
    background-color: #a4d9ec;
    min-width: 265px;
    width: 100%;
    overflow: auto;
    top: 30px;
    right: 0;

    fieldset {
        display: block;
        padding: 0 18px 10px;

        label {
            display: block;
            font-size: 12px;
            color: $azul_escuro;
            text-transform: uppercase;
            padding: 10px 0 8px;
        }
    }

    .centerLink {
        text-align: center;
        padding-bottom: 10px;
    }

    input {
        display: block;

        &[type='text'],
        &[type='password'],
        &[type='mask'] {
            background: $blank;
            border: none;
            font-size: 12px;
            color: #000;
            padding: 5px;
            width: 100%;
            outline: none !important;
            height: 30px;
            line-height: 30px;
        }

        &[type='submit'],
        &[type='buttom'] {
            border: none;
            width: 100%;
            height: 40px;
            background: #007ba8;
            position: relative;
            font-size: 18px;
            color: $blank;
            cursor: pointer;
            outline: none !important;
            text-transform: uppercase;
            text-align: center;
            clear: both;
            @include transition2s;

            &:hover {
                background-color: $azul_claro;
            }
        }
    }

    a {
        @include transition2s;

        &.lk_esquecisenha,
        &.lk_semcadastro {
            display: inline-block;
            font-size: 10px;
            color: $azul_escuro;
            position: relative;
        }

        &.lk_esquecisenha {
            &::after {
                content: "|";
                color: $azul_escuro;
                margin: 0 10px;
                display: inline-block;
                font-size: 10px;
                font-family: $opensans;
            }
        }

        &:hover {
            color: $cinza_textos;
        }
    }

    .instruction {
        display: block;
        font-size: 11px;
        color: $azul_escuro;
        padding: 20px 30px 16px 16px;
        text-transform: uppercase;
        line-height: 14px;
    }

    .closebox {
        position: relative;
        display: block;

        &::after {
            content: "X";
            display: block;
            width: 15px;
            height: 15px;
            text-align: center;
            line-height: 15px;
            right: 5px;
            top: 5px;
            font-size: 11px;
            font-weight: bold;
            color: $blank;
            position: absolute;
            cursor: pointer;
            background-color: $azul_escuro;
        }
    }
}

div.esqueci_senha {
    hr {
        display: block;
        height: 1px;
        background: #62b3d1;
        width: 60%;
        margin: 0 auto 10px;
        border: none;
    }
}

.nav-is-visible {

    div.meu_cadastro,
    div.esqueci_senha {
        display: none !important;
    }
}

.bxslider {
    a {
        display: block;
        width: 100%;

        .relPosition {
            position: relative;
        }

        .textosBanner {
            position: absolute;
            top: 100px;
            width: 470px;
            padding-right: 30px;
            text-align: right;
            z-index: 40;
            right: 155px;
        }

        .title_banner {
            font-size: 31px;
            color: $blank;
            text-transform: uppercase;
            font-weight: 700;
            text-align: right;
            width: 410px;
            display: inline;
            line-height: 39px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
            padding: 0 8px 5px 10px;
        }

        .desc_banner {
            font-size: 12px;
            color: $azul_escuro;
            text-align: right;
            width: 180px;
            display: inline-block;
            clear: both;
            margin-top: 20px;
            line-height: 14px;
            padding: 8px;
        }

        .imagebox {
            overflow: hidden;
        }
    }
}

@media only screen and (max-width: $shelves-tablet-breakpoint) {
    .bxslider {
        a {
            display: block;
            width: 100%;

            .relPosition {
                position: relative;
            }

            .textosBanner {
                position: relative;
                top: 0px;
                width: 100%;
                padding-right: 30px;
                padding-left: 30px;
                text-align: left;
                z-index: 40;
                right: inherit;
            }

            .title_banner {
                font-size: 25px;
                color: $blank;
                text-transform: uppercase;
                font-weight: 700;
                text-align: left;
                width: 100%;
                display: block;
                line-height: 30px;
                -webkit-box-decoration-break: clone;
                box-decoration-break: clone;
                padding: 8px 0;
            }

            .desc_banner {
                font-size: 12px;
                color: $azul_escuro;
                text-align: left;
                width: 100%;
                display: block;
                clear: both;
                line-height: 14px;
                margin-top: 0;
                padding: 4px 0 15px;
            }

            .imagebox {
                overflow: hidden;
                position: relative;
                height: 220px;

                img {
                    width: 100%;
                }
            }
        }
    }

    .bx-wrapper .bx-pager {
        top: 0;
    }
}

@media only screen and (max-width: $shelves-mobile-breakpoint) {
    .bxslider {
        a {
            .imagebox {
                height: auto;
            }

            .title_banner {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }
}

.item_destaque {
    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        margin-top: 10px;
    }

    a {
        border: 1px solid $cinza_bordas;
        border-bottom: 2px solid $azul_claro;
        margin: 0px 5px;
        min-height: 352px;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            margin: 10px 5px;
        }

        display: block;

        figure {
            @include transition5s;

            img {
                display: block;
                margin: 0 auto;
            }
        }

        .produto_desc {
            padding: 15px 12px;
            @include transition5s;
            height: 87px;

            h2 {
                font-size: 12px;
                color: $azul_escuro;
                text-transform: uppercase;
                display: block;
                text-align: center;
            }

            small {
                display: block;
                text-align: center;
                font-size: 12px;
                color: #999;
                padding: 5px 0;
                @include transition5s;
            }
        }

        &:hover {
            figure {
                -webkit-box-shadow: inset 0px 0px 53px -20px rgba(0, 0, 0, 0.35);
                -moz-box-shadow: inset 0px 0px 53px -20px rgba(0, 0, 0, 0.35);
                box-shadow: inset 0px 0px 53px -20px rgba(0, 0, 0, 0.35);
            }

            .produto_desc {
                background-color: $azul_claro;
                height: 87px;

                small {
                    color: $blank;
                }
            }
        }

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            .produto_desc {
                background-color: $azul_claro;

                small {
                    color: $blank;
                }
            }
        }
    }
}

.fullBlank {
    width: 100%;
    background-color: $blank;
    padding: 20px 0;
}

.fullBlank-Nbord {
    width: 100%;
    background-color: $blank;
    padding: 0;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        padding: 0 8px;
    }
}

.menu-Nbord {
    padding: 0 0 30px;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        padding: 0;
    }
}

.fullGrey {
    background: #e1e1e1;
    padding: 20px 0;

    .banner-videos {
        padding: 0 10px 0 0;
    } 
    
    @media only screen and (max-width: 1024px) {
        .banner-videos {
            padding: 0;
            margin-bottom: 20px;
        } 

        .banner-meus-pedidos {
            display: flex;
            justify-content: center;
        }
    }    
}

footer .container-fluid {
    background-color: #ebebeb;

    & > .row {
        display: flex;
    }

    .inner_padding {
        padding: 20px 0;
    }

    .whatspapp {
        .fa {
            display: inline-block;
            vertical-align: middle;
            font-size: 40px;
            margin-right: 6px;
        }
        
        span {
            display: inline-block;
            vertical-align: middle;
            max-width: 123px;
            text-align: left;
            font-size: 10px;
        }

        b {
            font-size: 15px;
            font-style: inherit;
            font-weight: 700;
        }
    }

    .fullGrey {
        .telefoneMap {
            border-top: 1px solid #b4b4b4;
            text-align: center;

            .filialName {
                border: none !important;
            }
        }
    }
    @media only screen and (max-width: 1170px) {
        .column-9 {
            width: 100%;
        }
        .mapasite .verticalBottom {
            .externa-link img {
                margin: 0 auto;
            }

            .telefoneMap {
                margin: 0 15%;

                .filialName {
                    display: block;
                }
            }
        }
    }
    @media only screen and (max-width: 860px) {
        & > .row {
            flex-wrap: wrap;
        }
        .fullGrey {
            width: 100%;
        }
        .inner_padding {
            .tablet-column-1  {
                width: 33%;
                text-align: center;
            }
        }
    }
}

.mobicrumb {
    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        padding: 10px 0;
    }
}

.fullGrey-Nbord {
    width: 100%;
    background-color: #ebebeb;
    padding: 0;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        padding: 0 8px;
    }
}

.fullBlue {
    width: 100%;
    background-color: $azul_escuro;
    padding: 0;
}

.fullGreen {
    width: 100%;
    background-color: $green;
    padding: 0;
}

.greyColumn {
    background-color: #ebebeb;
}

.title {
    display: block;

    h1 {
        font-size: 24px;
        color: $azul_escuro;
        text-align: center;
        display: block;
        padding: 10px;
        text-transform: uppercase;
    }
}

.titleBlue {
    display: block;
    background-color: $azul_escuro;
    margin: 0px 0 10px;

    h1 {
        font-size: 24px;
        color: $blank;
        text-align: center;
        display: block;
        padding: 15px;
        text-transform: uppercase;
    }
}

.LinkVideo {
    position: relative;
    display: block;
    overflow: hidden;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        margin-bottom: 15px;
    }

    .layerblack {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.3);
        background-image: url(../images/btPlay.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 10;

        h1 {
            background-color: $azul_escuro;
            display: inline-block;
            font-size: 24px;
            color: $blank;
            position: absolute;
            left: 0;
            top: 0;
            padding: 20px;
        }

        .desc {
            background-color: $azul_claro;
            font-size: 14px;
            color: $blank;
            width: 100%;
            display: block;
            padding: 18px 20px;
            bottom: 0;
            position: absolute;
        }
    }

    img {
        position: relative;
        display: block;
        @include transition7s;
        z-index: 1;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            position: relative;
            width: 100%;
        }
    }

    &:hover {
        img {
            @media only screen and (min-width: $shelves-tablet-breakpoint) {
                -webkit-transform: scale(1.25);
                -moz-transform: scale(1.25);
                -ms-transform: scale(1.25);
                -o-transform: scale(1.25);
                transform: scale(1.25);
            }
        }
    }
}

.news {
    background-color: #00afef;
    padding: 40px 0;
    margin-top: 20px;

    .title {
        margin-bottom: 25px;

        h1 {
            font-size: 24px;
            color: #fff;
            margin: 0;
            padding: 0;
        }
    }    

    .form {        

        .box-fields {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        
            .box-input {
                padding-right: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;
                height: 52px;
                justify-content: flex-end;
    
                label {
                    font-size: 12px;
                    color: $blank;
                    display: block;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }
    
                input {
                    display: block;
        
                    &[type='text'],
                    &[type='email'],
                    &[type='mask'] {
                        background: $blank;
                        border: none;
                        font-size: 12px;
                        color: #000;
                        padding: 5px;
                        width: 100%;
                        outline: none !important;
                        height: 30px;
                        line-height: 30px;
                    }
        
                    &[type='submit'],
                    &[type='buttom'] {
                        border: none;
                        width: 100%;
                        height: 30px;
                        background: #007ba8;
                        position: relative;
                        font-size: 18px;
                        color: $blank;
                        cursor: pointer;
                        outline: none !important;
                        text-transform: uppercase;
                        text-align: center;
                        @include transition2s;
        
                        &:hover {
                            background-color: $azul_escuro;
                        }
                    }
                }
    
                .relativeSelect {
                    position: relative;
                    width: 100%;
                }
    
                .select-modify,
                .select-dynamic {
                    height: 30px;
                    position: relative;
                    text-align: left;
                    width: 100%;
                    z-index: 2;
                    cursor: pointer;
                    @include opacity00;
    
                    &:focus {
                        outline: none;
                    }
                }
    
                .selectSpan {
                    background: $blank url("../images/select.png") no-repeat scroll 98% center;
                    border: none;
                    bottom: 0;
                    cursor: default;
                    float: left;
                    height: 30px;
                    left: 0;
                    line-height: 30px;
                    position: absolute;
                    text-align: left;
                    text-indent: 10px;
                    width: 100%;
                    z-index: 1;
                    cursor: pointer;
                    border: none;
                    font-size: 12px;
                    color: #000;
                }
            }
        }

        .box-privacy-policy {
            font-size: 14px;
            color: #ffffff;
            display: flex;
            align-items: center;

            input {
                margin-left: 0;
            }

            a {
                color: #ffffff;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }


            label.labcheck[for] {
                position: relative;
                padding: 0 0 0 20px;
                cursor: pointer;
                text-transform: none;              
                line-height: 1.3;  

                &.label_privacy_policy_error {
                    color: #ff0000;
        
                    a {
                        color: #ff0000;
                    }
                }
            }
        
            label.labcheck[for]:before {
                content: '';
                position: absolute;
                border: 1px solid #999;
                background: #fff;
                width: 12px;
                height: 12px;
                top: 0px;
                left: 0px;
            }
        
            label.labcheck[for]:after {
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                opacity: 0;
                content: '';
                position: absolute;
                width: 14px;
                height: 7px;
                background-color: transparent;
                top: 0px;
                left: 0px;
                border-left: 3px solid #252642;
                border-bottom: 2px solid #252642;
                border-top: none;
                border-right: none;
                -webkit-transform: rotate(-55deg);
                -moz-transform: rotate(-55deg);
                -o-transform: rotate(-55deg);
                -ms-transform: rotate(-55deg);
                transform: rotate(-55deg);
            }
        
            .checkbox:checked+label.labcheck:after {
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                filter: alpha(opacity=100);
                opacity: 1;
            }                    
        }

        .messages {
            height: 25px;
            display: flex;
            align-items: center;
        }
    }

    @media only screen and (max-width: 1060px) {        
        .form {
            padding: 0 20px;

            .box-fields {
                flex-direction: column;

                .box-input {
                    padding-right: 0;
                    height: unset;
                    justify-content: unset;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 425px) {    
        .title {
            h1 {
                font-size: 19px;
            }
        } 
    }
}

.box-banner-meus-pedidos {
   
}

.item_canal {
    margin-bottom: 10px;
    padding: 0 5px;
    min-height: 525px;

    a {
        display: block;

        figure {
            @include transition5s;

            img {
                display: block;
                margin: 0 auto;
                width: 100%;
            }
        }

        small {
            font-size: 12px;
            color: $blank;
            display: inline-block;
            background-color: $azul_claro;
            padding: 14px;
            text-transform: uppercase;
            @include transition5s;
        }

        .canal_desc {
            padding: 17px 14px 0;
            min-height: 210px;
            position: relative;
            border-bottom: 2px solid $azul_claro;

            h1 {
                font-size: 16px;
                color: $azul_escuro;
                text-transform: uppercase;
                font-weight: 400;
                padding-bottom: 17px;
            }

            p {
                font-size: 12px;
                color: $cinza_textos;
                line-height: 14px;
                padding-bottom: 17px;
            }

            .boxlink {
                margin-bottom: 0;
                right: 5%;
                bottom: 0;
                position: absolute;
                text-align: right;
                @include transition5s;
                font-size: 10px;
                color: $azul_escuro;
                text-transform: uppercase;
                padding-bottom: 10px;

                &::after {
                    @include transition5s;
                    content: '\e801';
                    font-size: 10px;
                    display: inline-block;
                    font-family: "fontello";
                    line-height: 14px;
                    margin-left: 3px;
                    color: $azul_escuro;
                }
            }
        }

        &:hover {
            .boxlink {
                color: $azul_claro;

                &::after {
                    color: $azul_claro;
                }
            }

            small {
                background-color: #007ba8;
            }
        }
    }
}

.footer-fique-por-dentro {   
    display: flex;
    align-items: center;
    justify-content: center;  
    padding: 15px 0;
    clear: both;
    
    .footer-fique-por-dentro-menu {
        ul {
            li {
                display: block;
                padding-bottom: 3px;
                text-align: center;
                
                a {
                    font-size: 11px;
                    color: #999;
                    @include transition2s;
                    text-transform: uppercase;
    
                    &:hover {
                        color: $azul_claro;
                        border-bottom: 1px solid $azul_claro;
                    }
                }
            }
        }
    }
}

.mapasite {
    &.fullGrey {
        padding-bottom: 0;
    }
    ul {
        padding-bottom: 10px;

        li {
            display: block;
            padding-bottom: 3px;

            a {
                font-size: 10px;
                color: #999;
                @include transition2s;
                text-transform: uppercase;

                &:hover {
                    color: $azul_claro;
                    border-bottom: 1px solid $azul_claro;
                }
            }
        }

        h3 {
            display: block;
            font-weight: 400;
            padding-bottom: 5px;
            margin-top: 10px;

            a {
                font-size: 14px;
                color: $azul_escuro;
                @include transition2s;
                text-transform: uppercase;

                &:hover {
                    color: $azul_claro;
                    border-bottom: 1px solid $azul_claro;
                }
            }
        }
    }

    .redes-sociais {
        position: relative;
        bottom: 0;
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: center;

        a {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: 70px;
            color: #252642;
            font-size: 40px;
            transition: all ease 0.5s;

            &:hover {
                opacity: 0.5;
            }
        }

        .facebook {
            background: #d6d6d6;
        }

        .instagram {
            background: #bfbfbf;
        }

        .youtube {
            background: #cacaca;
        }
    }

    .verticalBottom {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        @media only screen and (max-width: $shelves-tablet-breakpoint) {
            padding-left: 8px;
        }

        .externa-link {
            display: block;
            margin: 10px auto;
            padding: 0px 15% 0 10%;

            img {
                display: block;
                margin: 0 auto;
            }

            @media only screen and (max-width: $shelves-tablet-breakpoint) {
                margin-left: 0;
                padding: 0px;

                img {
                    margin: 0;
                }
            }
        }

        .telefoneMap {
            margin: 0px 15%;

            a {
                color: #000;
            }

            @media only screen and (max-width: $shelves-tablet-breakpoint) {
                margin-left: 0;
                padding: 0px;
            }

            .filialName {
                font-size: 11px;
                text-transform: uppercase;
                padding-top: 15px;
                border-top: 1px solid #b4b4b4;
                display: inline-block;
                padding-bottom: 3px;
                color: #252642;
            }

            .filialNumber {
                font-size: 24px;
                color: $azul_escuro;
                font-weight: 700;
                display: inline-block;
                padding-bottom: 15px;

                @media only screen and (max-width: $shelves-tablet-breakpoint) {
                    font-size: 16px;
                }

                span {
                    display: inline-block;
                    vertical-align: top;
                }

                .filialDDD {
                    font-size: 10px;
                    font-weight: 700;
                    padding-top: 2px;
                    margin-right: 4px;
                }
            }
        }
    }
}

.lkinatlas {
    background: url(../images/logo_footer.png) no-repeat 0 0;
    width: 174px;
    height: 39px;
    @include hide-text;
    margin: 0;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        margin: 10px auto;
    }
}

.nav-is-fixed {
    padding-top: 165px;
}

.padmobile {
    @media only screen and (max-width: $shelves-tablet-breakpoint) {
        padding: 8px;
    }
}

.padcell {
    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        padding: 8px;
    }
}

.lkinbetta {
    width: 89px;
    height: 36px;
    @include hide-text;
    margin: 0;
    margin-top: 5px;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        margin: 10px auto;
    }
}

.lkib_pt {
    background: url(../images/pt/selo_inbetta.png) no-repeat 0 0;
}

.lkib_en {
    background: url(../images/pt/selo_inbetta.png) no-repeat 0 0;
}

.lkib_es {
    background: url(../images/pt/selo_inbetta.png) no-repeat 0 0;
}

.alinhaAssina {
    text-align: right;
    margin-top: 10px;

    .lk_assina {
        font-size: 11px;
        color: #7d7d7d;
        margin: 0;
    }

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        text-align: center;
        padding-bottom: 10px;
    }
}

.borderBlue {
    border-bottom: 1px solid $azul_claro;
}

.banner_topo_interna {
    position: relative;

    img {
        display: block;
    }

    .texto_banner_interna {
        position: absolute;
        top: 15px;
        display: inline-block;
        right: 0;
        padding: 15px 20px 15px 15px;
        text-align: right;
        font-weight: 700;
        font-size: 31px;
        line-height: 30px;
        background-color: $azul_escuro;
        color: $blank;
        width: 37%;
        vertical-align: middle;
        text-transform: uppercase;

        @media only screen and (max-width: 700px) {
            top: auto;
            right: auto;
            width: 100%;
            text-align: left;
            position: relative;
            font-size: 25px;
        }

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            font-size: 15px;
            line-height: 20px;
        }
    }
}

.breadcrumbs {
    display: block;
    padding: 8px 0;

    @media only screen and (max-width: $shelves-tablet-breakpoint) {
        padding: 8px 20px;
    }

    li {
        color: $cinza_textos;
        font-size: 12px;
        position: relative;
        display: inline-block;

        a {
            color: $cinza_textos;
            display: inline-block;
            position: relative;
            padding-bottom: 3px;

            &::after {
                content: " ";
                width: 1px;
                height: 1px;
                display: block;
                @include opacity00;
                @include transition3s;
                background-color: #7b7b7b;
                position: absolute;
                left: 0;
                bottom: 0;
            }

            &:hover {
                &::after {
                    width: 100%;
                    @include opacity01;
                }
            }

            &.active {
                font-weight: 600;
                color: #00b4cd;

                &:hover {
                    cursor: default;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        &::after {
            content: '\e801';
            font-size: 9px;
            display: inline-block;
            font-family: "fontello";
            line-height: 14px;
            margin-left: 8px;
            margin-right: 5px;
            color: $azul_escuro;
            position: relative;
            top: -1px;
        }

        &:first-child {
            &::before {
                content: ' ';
                display: inline-block;
                margin-right: 5px;
                width: 12px;
                height: 12px;
                background: url(../images/ico_casinha.png) no-repeat 0 0;
            }
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.menu_sidebar {
    background: #ededed;
    height: 100%;
    position: relative;

    h2 {
        background: $azul_escuro;
        padding: 15px 20px;
        font-weight: 400;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        color: $blank;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            display: none;
        }
    }

    ul {
        ul {
            padding: 25px 20px;

            @media only screen and (min-width: $shelves-mobile-breakpoint) {
                display: block;
            }

            li {
                display: block;
                font-size: 14px;

                @media only screen and (min-width: $shelves-mobile-breakpoint) {
                    a {
                        color: #555;
                        position: relative;
                        padding-bottom: 3px;
                        margin-bottom: 3px;
                        display: inline-block;

                        &::after {
                            content: " ";
                            width: 1px;
                            height: 1px;
                            display: block;
                            @include opacity00;
                            @include transition3s;
                            background-color: #7b7b7b;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }

                        &:hover {
                            &::after {
                                width: 100%;
                                @include opacity01;
                            }
                        }

                        &.active {
                            color: #00b4cd;

                            &:hover {
                                cursor: default;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.inner_padding {
    @media only screen and (max-width: $shelves-tablet-breakpoint) {
        padding: 0px 20px;
    }
}

.inner_content_internas {
    display: block;
    padding-bottom: 30px;

    h3 {
        width: 100%;
        padding: 40px 20px 20px;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            padding: 10px 20px 10px;
        }

        display: block;
        text-align: center;
        text-transform: uppercase;
        color: $azul_escuro;
        font-weight: 400;
        display: block;
        font-size: 24px;
    }

    h4.minititle {
        width: 100%;
        padding: 10px;
        display: block;
        text-align: center;
        text-transform: uppercase;
        color: $blank;
        font-weight: 600;
        display: block;
        background: $azul_escuro;
        margin: 15px 0 25px;
    }

    img {
        display: block;
        width: 100%;
        margin: 0;
    }

    p {
        display: block;
        color: $cinza_textos;
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 10px;
    }

    .unidades_list {
        ul {
            li {
                display: block;
                padding-left: 50px;
                position: relative;

                &::before {
                    background-repeat: no-repeat;
                    background-image: url(../images/icons_institucional.png);
                    content: " ";
                    display: block;
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                &.unicon_01 {
                    &::before {
                        background-position: 0 0;
                    }
                }

                &.unicon_02 {
                    &::before {
                        background-position: 0 -102px;
                    }
                }

                &.unicon_03 {
                    &::before {
                        background-position: 0 -193px;
                    }
                }

                &.unicon_04 {
                    &::before {
                        background-position: 0 -290px;
                    }
                }

                h5 {
                    display: block;
                    color: $azul_escuro;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 600;
                    line-height: 14px;
                }

                h6 {
                    display: block;
                    color: $azul_escuro;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14px;
                }

                address {
                    display: block;
                    color: $cinza_textos;
                    font-size: 12px;
                    line-height: 14px;
                    padding-top: 3px;

                    a {
                        color: $cinza_textos;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                hr {
                    background: #d5d5d5;
                    height: 1px;
                    border: none;
                    padding: 0;
                    margin: 15px 0;
                    display: block;
                    width: 230px;
                }
            }
        }
    }
}

.hidden-acordeon {
    display: none;
}

@media only screen and (max-width: $shelves-mobile-breakpoint) {
    .hidden-acordeon {
        display: inline-block;
    }

    .cd-accordion-menu {
        width: 100%;
        max-width: 600px;
        background: #ededed;
        margin: 0;
        position: relative;
    }

    .cd-accordion-menu ul {
        /* by default hide all sub menus */
        display: none;
        padding: 0 !important;
    }

    .cd-accordion-menu .trigger-accordeon {
        color: transparent;
        display: block;
        height: 44px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 44px;
        z-index: 50;
        right: 20px;
        top: 0;
        cursor: pointer;
    }

    .cd-accordion-menu .trigger-accordeon span,
    .cd-accordion-menu .trigger-accordeon span::before,
    .cd-accordion-menu .trigger-accordeon span::after {
        /* hamburger icon in CSS */
        position: absolute;
        display: inline-block;
        height: 3px;
        width: 24px;
        background: $blank;
    }

    .trigger-accordeon span {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -2px;
        -webkit-transition: background 0.3s 0.3s;
        -moz-transition: background 0.3s 0.3s;
        transition: background 0.3s 0.3s;
    }

    .trigger-accordeon span::before,
    .trigger-accordeon span::after {
        content: '';
        right: 0;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform-origin: 0% 50%;
        -moz-transform-origin: 0% 50%;
        -ms-transform-origin: 0% 50%;
        -o-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-transition: -webkit-transform 0.3s 0.3s;
        -moz-transition: -moz-transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s;
        background: $blank;
    }

    .trigger-accordeon span::before {
        /* menu icon top line */
        top: -6px;
    }

    .trigger-accordeon span::after {
        /* menu icon bottom line */
        top: 6px;
    }

    .cd-accordion-menu li {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .cd-accordion-menu input[type=checkbox] {
        /* hide native checkbox */
        position: absolute;
        opacity: 0;
    }

    .cd-accordion-menu label,
    .cd-accordion-menu a {
        position: relative;
        display: block;
        padding: 15px 20px 15px 40px;
        background: $azul_escuro;
        color: $blank;
        font-size: 16px;
    }

    .no-touch .cd-accordion-menu label:hover,
    .no-touch .cd-accordion-menu a:hover {
        background: #00b4cd;
    }

    .cd-accordion-menu label::before,
    .cd-accordion-menu a::after {
        /* icons */
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .cd-accordion-menu label {
        cursor: pointer;
    }

    .cd-accordion-menu label::before {
        background-image: url(../images/cd-icons.svg);
        background-repeat: no-repeat;
    }

    .cd-accordion-menu label::before {
        /* arrow icon */
        left: 18px;
        background-position: 0 0;
        -webkit-transform: translateY(-50%) rotate(-90deg);
        -moz-transform: translateY(-50%) rotate(-90deg);
        -ms-transform: translateY(-50%) rotate(-90deg);
        -o-transform: translateY(-50%) rotate(-90deg);
        transform: translateY(-50%) rotate(-90deg);
    }

    .cd-accordion-menu a::after {
        /* image icon */
        left: 36px;
        background: url(../images/cd-icons.svg) no-repeat -48px 0;
    }

    .cd-accordion-menu input[type=checkbox]:checked+label::before {
        /* rotate arrow */
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .cd-accordion-menu input[type=checkbox]:checked+label::after {
        /* show open folder icon if item is checked */
        background-position: -32px 0;
    }

    .cd-accordion-menu input[type=checkbox]:checked+label+ul,
    .cd-accordion-menu input[type=checkbox]:checked+label:nth-of-type(n)+ul {
        /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
        /* show children when item is checked */
        display: block;
    }

    .cd-accordion-menu ul label,
    .cd-accordion-menu ul a {
        background: none;
        padding-left: 20px;
        color: #555;

        &.active {
            color: #00b4cd;
        }
    }

    .no-touch .cd-accordion-menu ul label:hover,
    .no-touch .cd-accordion-menu ul a:hover {
        background: #ededed;
    }

    .cd-accordion-menu>li:last-of-type>label,
    .cd-accordion-menu>li:last-of-type>a,
    .cd-accordion-menu>li>ul>li:last-of-type label,
    .cd-accordion-menu>li>ul>li:last-of-type a {
        box-shadow: none;
    }

    .cd-accordion-menu ul label::before {
        left: 36px;
    }

    .cd-accordion-menu ul label::after,
    .cd-accordion-menu ul a::after {
        left: 59px;
    }

    .cd-accordion-menu ul a::after {
        display: none;
    }

    .cd-accordion-menu ul ul label,
    .cd-accordion-menu ul ul a {
        padding-left: 100px;
    }

    .cd-accordion-menu ul ul label::before {
        left: 54px;
    }

    .cd-accordion-menu ul ul label::after,
    .cd-accordion-menu ul ul a::after {
        left: 77px;
    }

    .cd-accordion-menu ul ul ul label,
    .cd-accordion-menu ul ul ul a {
        padding-left: 118px;
    }

    .cd-accordion-menu ul ul ul label::before {
        left: 72px;
    }

    .cd-accordion-menu ul ul ul label::after,
    .cd-accordion-menu ul ul ul a::after {
        left: 95px;
    }

    .cd-accordion-menu.animated label::before {
        /* this class is used if you're using jquery to animate the accordion */
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }
}

p {
    strong {
        font-weight: 700;
    }
}

.empresaItens {
    background: #ededed;
    margin-top: 15px;

    h4 {
        color: $blank;
        display: inline-block;
        font-size: 18px;
        font-family: $opensans;
        padding: 9px;
        background-color: #00b4cd;
    }

    p {
        color: $azul_escuro;
        display: block;
        font-size: 18px;
        font-family: $opensans;
        padding: 20px;
        line-height: 22px;
    }

    ul {
        padding: 20px;

        li {
            font-size: 18px;
            font-family: $opensans;
            color: $azul_escuro;
            padding: 5px 0;
            line-height: 22px;

            &::before {
                content: "\2022";
                color: #00b4cd;
                margin-right: 4px;
                font-family: $opensans;
                font-size: 18px;
            }
        }
    }
}

#page_institucional {
    .imagePage {
        display: block;
        margin: 20px 0 0 0;
    }
}

#page_inbetta {
    iframe {
        margin-top: 15px;
        display: block;

        @media only screen and (max-width: $shelves-tablet-breakpoint) {
            width: 100%;
            height: 300px;
        }
    }

    .imagePage {
        display: block;
        margin: 0 0 20px 0;
    }
}

.navlink_cnt {
    text-align: center;
    padding: 15px 20px 20px;
}

.linkBotao {
    color: $blank;
    display: inline-block;
    font-size: 18px;
    font-family: $opensans;
    padding: 9px;
    background-color: #00b4cd;
    @include transition1s;

    &:hover {
        background-color: #01a4bb;
    }
}

.canalMenu {
    @media only screen and (min-width: $shelves-mobile-breakpoint) {
        text-align: center;
        background-color: $azul_claro;
        display: block;

        li {
            display: inline-block;
            text-transform: uppercase;
            padding-bottom: 9px;

            a {
                font-size: 14px;
                font-family: $opensans;
                padding: 12px 0 3px 0;
                display: inline-block;
                color: $blank;
                position: relative;
                @include underlineAnimate;

                &::after {
                    background-color: $blank;
                }

                &.active {
                    color: $azul_escuro;

                    &:hover {
                        &::after {
                            display: none;
                        }
                    }
                }
            }

            &::after {
                content: "|";
                color: $blank;
                margin: 0 10px;
                display: inline-block;
                font-size: 14px;
                font-family: $opensans;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}

#destaque-canal {
    margin-top: 25px;

    .item-canal {
        background-color: #fff;
        padding: 16px;
        margin-bottom: 20px;
    }

    .decDestaque {
        padding: 8px 20px;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            padding: 8px 0px;
        }

        .inner-breads {
            vertical-align: middle;

            li {
                position: relative;
                display: inline-block;

                &::before {
                    content: "\2022";
                    margin: 0 10px;
                    font-size: 14px;
                    font-family: $opensans;
                    color: #00afef;
                    display: inline-block;
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }

                a {
                    text-transform: uppercase;
                    font-size: 14px;
                    font-family: $opensans;
                    color: #00afef;
                    display: inline-block;
                    position: relative;
                    padding-bottom: 3px;
                    @include underlineAnimate;

                    &::after {
                        background-color: #00afef;
                    }
                }

                time {
                    color: $cinza_textos;
                    content: "\2022";
                    font-size: 14px;
                    font-family: $opensans;
                    display: inline-block;

                    &::before {
                        content: "\007C";
                        margin: 0 10px;
                        display: inline-block;
                    }
                }
            }
        }

        h2 {
            color: $azul_escuro;
            font-size: 32px;
            font-family: $opensans;
            line-height: 35px;
            text-transform: uppercase;
            margin-top: 10px;

            @media only screen and (max-width: $shelves-mobile-breakpoint) {
                font-size: 22px;
                line-height: 25px;
            }
        }

        figcaption {
            color: $cinza_textos;
            font-size: 12px;
            font-family: $opensans;
            line-height: 14px;
            margin-top: 10px;
        }
    }

    .LinkVideoDestaque {
        position: relative;
        display: block;
        overflow: hidden;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            margin-bottom: 15px;
        }

        .layerblack {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            background: rgba(0, 0, 0, 0.3);
            background-image: url(../images/btPlay.png);
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 10;
        }

        img {
            position: relative;
            display: block;
            @include transition7s;
            z-index: 1;

            @media only screen and (max-width: $shelves-mobile-breakpoint) {
                position: relative;
                width: 100%;
            }
        }

        &:hover {
            img {
                @media only screen and (min-width: $shelves-tablet-breakpoint) {
                    -webkit-transform: scale(1.25);
                    -moz-transform: scale(1.25);
                    -ms-transform: scale(1.25);
                    -o-transform: scale(1.25);
                    transform: scale(1.25);
                }
            }
        }
    }
}

.social-bar {
    vertical-align: middle;
    margin-top: 10px;

    legend {
        color: $cinza_textos;
        font-size: 11px;
        font-family: $opensans;
        padding: 9px 0 0;
        text-transform: uppercase;
        display: inline-block;
        float: left;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            display: block;
            float: none;
            text-align: center;
            width: 100%;
            padding: 10px 0 8px;
        }
    }

    .share-bar {
        display: inline-block;
        margin-left: 15px;
        width: 190px;
        float: left;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            margin-left: 0px;
        }
    }

    .fb-like {
        display: inline-block;
        margin-top: 3px;
        float: left;
    }
}

.seletorVideos {
    padding-top: 20px;
    padding-bottom: 35px;

    label {
        font-size: 12px;
        color: $azul_escuro;
        text-transform: uppercase;
        display: block;
        padding: 0 0 8px 0;
    }

    select {
        background: $blank;
        border: 1px solid #999;
        border-left: 1px solid #00b4cd;
        font-size: 12px;
        color: $cinza_textos;
        padding: 5px;
        width: calc(100% - 85px);
        outline: none !important;
        display: block;
        float: left;
        height: 30px;
    }

    input {
        display: block;
        float: left;

        &[type='submit'],
        &[type='button'] {
            min-width: 80px;
            border: none;
            width: 13%;
            margin-left: 1%;
            height: 30px;
            background: $azul_escuro;
            position: relative;
            cursor: pointer;
            outline: none !important;
            font-size: 16px;
            color: $blank;
            text-transform: uppercase;
            text-align: center;
        }

        @include transition1s;

        &:hover {
            background-color: $azul_claro;
        }
    }
}

.pinGridWrapper {
    width: 100%;
    margin: 8px auto 8px;
}

/*custom widths for masonry*/

.masonry-grid {
    margin: 0 -8px;
}

.masonry-grid .col {
    margin-left: 0;
    margin-bottom: 8px;
    padding: 0 4px;
}

.masonry-grid .masonry-entry.span_1_of_3 {
    width: 33.3%;
}

@media only screen and (max-width: 1170px) {
    .masonry-grid .masonry-entry.span_1_of_3 {
        width: 50%;
    }
}

@media only screen and (max-width: $shelves-mobile-breakpoint) {
    .masonry-grid .masonry-entry.span_1_of_3 {
        width: 100%;
    }
}

/* The Grid
================================================== */

.col:after,
.clr:after,
.group:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    zoom: 1;
}

.col {
    display: block;
    float: left;
    margin: 0 0 2% 1.6%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*clear float and remove left margin*/

.clr-margin {
    margin-left: 0;
}

.count-1 {
    clear: both;
    margin-left: 0;
}

.col-1,
.col.first {
    clear: both;
    margin-left: 0;
}

@media only screen and (max-width: 767px) {
    .col {
        width: 100%;
        margin-left: 0;
        margin-bottom: 25px;
    }

    .masonry-grid {
        margin-left: 0;
        margin-right: 0;
    }
}

.timeOuter {}

#listagemCanalAtlas {
    .outerBox {
        padding-bottom: 8px;
        padding-right: 8px;
    }

    .item-canal {
        padding: 16px;
        background-color: #fff;

        .inner-breads {
            vertical-align: middle;
            margin-top: 7px;

            li {
                position: relative;
                display: inline-block;

                &::before {
                    content: "\2022";
                    margin: 0 5px;
                    font-size: 12px;
                    font-family: $opensans;
                    color: #00afef;
                    display: inline-block;
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }

                a {
                    text-transform: uppercase;
                    font-size: 12px;
                    font-family: $opensans;
                    color: #00afef;
                    display: inline-block;
                    position: relative;
                    padding-bottom: 3px;
                    @include underlineAnimate;

                    &::after {
                        background-color: #00afef;
                    }
                }

                time {
                    color: $cinza_textos;
                    content: "\2022";
                    font-size: 12px;
                    font-family: $opensans;
                    display: inline-block;

                    &::before {
                        content: "\007C";
                        margin: 0 8px;
                        display: inline-block;
                    }
                }
            }
        }

        h2 {
            color: $azul_escuro;
            font-size: 16px;
            font-family: $opensans;
            line-height: 18px;
            text-transform: uppercase;
            margin-top: 7px;
        }

        figcaption {
            color: $cinza_textos;
            font-size: 12px;
            font-family: $opensans;
            line-height: 14px;
            margin-top: 7px;
        }

        .LinkListagem {
            position: relative;
            display: block;
            overflow: hidden;

            @media only screen and (max-width: $shelves-mobile-breakpoint) {
                margin-bottom: 15px;
            }

            .layerPlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                background: rgba(0, 0, 0, 0.3);
                background-image: url(../images/btPlay_peq.png);
                background-repeat: no-repeat;
                background-position: center center;
                z-index: 10;
            }

            img {
                position: relative;
                display: block;
                width: 100%;
                @include transition7s;
                z-index: 1;

                @media only screen and (max-width: $shelves-mobile-breakpoint) {
                    position: relative;
                    width: 100%;
                }
            }

            &:hover {
                img {
                    @media only screen and (min-width: $shelves-tablet-breakpoint) {
                        -webkit-transform: scale(1.25);
                        -moz-transform: scale(1.25);
                        -ms-transform: scale(1.25);
                        -o-transform: scale(1.25);
                        transform: scale(1.25);
                    }
                }
            }
        }
    }
}

.lkCarregarmais {
    color: $blank;
    font-size: 16px;
    font-family: $opensans;
    line-height: 16px;
    text-transform: uppercase;
    background-color: $azul_escuro;
    display: inline-block;
    padding: 10px 30px;
    top: -40px;
    position: relative;
    @include transition1s;

    &:hover {
        background-color: $azul_claro;
    }
}

#canal_atlas .algnCnt {
    text-align: center;
    margin-bottom: -20px;
}

#canal_atlas .padBottm {
    padding-bottom: 50px;
}

.inner_padding {
    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        padding: 10px;
    }
}

#detalhe-canal {
    .decDestalhe {
        padding: 8px 20px;

        iframe {
            max-width: 100%;
        }

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            padding: 8px 0px;
        }

        .inner-breads {
            vertical-align: middle;
            margin-bottom: 25px;

            li {
                position: relative;
                display: inline-block;

                &::before {
                    content: "\2022";
                    margin: 0 10px;
                    font-size: 14px;
                    font-family: $opensans;
                    color: #00afef;
                    display: inline-block;
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }

                a {
                    text-transform: uppercase;
                    font-size: 14px;
                    font-family: $opensans;
                    color: #00afef;
                    display: inline-block;
                    position: relative;
                    padding-bottom: 3px;
                    @include underlineAnimate;

                    &::after {
                        background-color: #00afef;
                    }
                }

                time {
                    color: $cinza_textos;
                    content: "\2022";
                    font-size: 14px;
                    font-family: $opensans;
                    display: inline-block;

                    &::before {
                        content: "\007C";
                        margin: 0 10px;
                        display: inline-block;
                    }
                }
            }
        }

        h2 {
            color: $azul_escuro;
            font-size: 32px;
            font-family: $opensans;
            line-height: 35px;
            text-transform: uppercase;
            margin-top: 10px;

            @media only screen and (max-width: $shelves-mobile-breakpoint) {
                font-size: 22px;
                line-height: 25px;
            }

            margin-bottom: 25px;
        }

        figcaption {
            color: $cinza_textos;
            font-size: 12px;
            font-family: $opensans;
            line-height: 14px;
            margin-top: 30px;
            display: block;

            strong {
                font-weight: 700;
            }

            p {
                padding-bottom: 10px;
                display: block;
                color: $cinza_textos;
                font-size: 12px;
                font-family: $opensans;
                line-height: 14px;
            }

            ul {
                padding-bottom: 10px;
                list-style-type: square;
                list-style-position: inside;

                li {
                    padding-bottom: 10px;
                    color: $cinza_textos;
                    font-size: 12px;
                    font-family: $opensans;
                    line-height: 14px;
                }
            }

            a {
                color: $cinza_textos;
                text-decoration: underline;
            }
        }

        .social-bar {
            margin-top: 10px;
            vertical-align: middle;
            text-align: center;

            legend {
                color: $cinza_textos;
                font-size: 11px;
                font-family: $opensans;
                padding: 9px 0 0;
                text-transform: uppercase;
                display: inline-block;
                margin-bottom: 10px;

                @media only screen and (max-width: $shelves-mobile-breakpoint) {
                    display: block;
                    float: none;
                    text-align: center;
                    width: 100%;
                    padding: 10px 0 8px;
                }
            }

            .share-bar {
                display: inline-block;
                margin-left: 15px;
                width: 100%;

                @media only screen and (max-width: $shelves-mobile-breakpoint) {
                    margin-left: 0px;
                }

                .share-button {
                    display: none;
                }
            }

            .fb-like {
                display: inline-block;
                margin-top: 3px;
                float: left;
            }
        }

        .prVinculados {
            font-size: 11px;
            font-family: $opensans;
            font-weight: 600;
            color: $azul_claro;
            padding: 20px 0;
            display: inline-block;
            float: left;

            a {
                color: $cinza_textos;
                @include transition1s;

                &::before {
                    content: " ";
                    display: inline-block;
                    margin-left: 3px;
                }

                &::after {
                    content: ",";
                    display: inline-block;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                &:hover {
                    color: $azul_escuro;
                    text-decoration: underline;
                }
            }
        }

        iframe {
            @media only screen and (max-width: $shelves-tablet-breakpoint) {
                width: 100%;
                height: 300px;
            }
        }
    }
}

#produtos {
    .texto_banner_interna {
        background-color: $green;
    }

    .menu_sidebar h2 {
        display: block;
    }

    .produtos-sidebar {
        width: 100%;
        max-width: 600px;
        background: #ededed;
        margin: 0;
        position: relative;
        padding-top: 17px;

        ul {
            display: none;
            padding: 0 !important;
            padding-bottom: 5px !important;

            &.open {
                display: block;
            }
        }

        .trigger-accordeon {
            color: transparent;
            display: block;
            height: 13px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 13px;
            z-index: 50;
            right: 14px;
            cursor: pointer;
            background: url(../images/accordeon.png) no-repeat 0 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        .trigger-accordeon.open {
            background: url(../images/accordeon.png) no-repeat -13px 0;
        }

        li {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        li.sidebarprJS {
            margin-bottom: 1px;
            position: relative;

            &::before {
                content: " ";
                display: block;
                height: 1px;
                width: 94%;
                left: 2%;
                position: absolute;
                bottom: 0;
                background-color: #ccc;
                z-index: 5;

                @media only screen and (max-width: $shelves-tablet-breakpoint) {
                    width: 100%;
                    left: 0%;
                }
            }

            a {
                position: relative;
                display: block;
                padding: 10px 30px 10px 20px;
                background: #ededed;
                color: #555;
                font-size: 14px;
                text-transform: none;

                @media only screen and (max-width: $shelves-mobile-breakpoint) {
                    font-size: 17px;
                    padding: 15px 30px 15px 20px;
                }

                &::after,
                &::before {
                    display: none;
                }
            }

            ul a {
                position: relative;
                display: block;
                padding: 3px 30px 3px 30px;
                background: #ededed;
                color: #555;
                font-size: 12px;
                text-transform: none;

                @media only screen and (max-width: $shelves-mobile-breakpoint) {
                    font-size: 14px;
                    padding: 8px 30px 8px 30px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            ul ul a {
                padding-left: 40px;
            }

            ul ul ul a {
                padding-left: 50px;
            }
        }
    }
}

.produto-list,
.categoria-list {
    padding: 0px 8px;
    margin-bottom: 10px;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        padding: 0px;
    }

    h3 {
        font-size: 18px;
        color: $blank;
        display: block;
        padding: 14px 16px;
        margin-bottom: 10px;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            margin-top: 10px;
        }
    }

    .content_list {
        margin-right: -8px;
        overflow: hidden;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            margin-right: 0px;
        }
    }

    .produto-item {
        border: 1px solid $cinza_bordas;
        width: 32.2%;
        margin-right: 8px;
        float: left;
        margin-bottom: 8px;

        @media only screen and (max-width: $shelves-tablet-breakpoint) {
            width: 31.7%;
        }

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            width: 96%;
            float: none;
            margin: 0 auto 8px auto;
        }

        a {
            h2 {
                font-size: 14px;
                color: $azul_escuro;
                display: block;
                padding: 12px;
                background-color: #ededed;
            }

            display: block;

            figure {
                @include transition5s;

                img {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                }
            }
        }
    }
}

.produto-list {
    .list_container {
        margin-top: 45px;

        h2 {
            text-transform: uppercase;
        }
    }
}

.busca_list {
    .list_container {
        margin-top: 0px;
    }

    h3 {
        text-transform: uppercase;
        background: $azul_escuro;
        color: #fff;
        font-size: 14px;

        span {
            font-style: italic;
            font-weight: 600;
        }

        strong {
            font-weight: 600;
        }
    }
}

.produto_detalhe {
    padding: 0px 8px;
    margin-bottom: 10px;

    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        padding: 0px;
    }

    h3 {
        font-size: 18px;
        color: $blank;
        display: block;
        padding: 14px 16px;
        margin-bottom: 10px;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            margin-top: 10px;
        }
    }

    .selos_produto {
        overflow: hidden;
        margin-top: 40px;

        figure {
            display: inline-block;
            float: left;
            margin-bottom: 8px;
            margin-right: 8px;

            img {
                display: block;
            }
        }
    }

    .lkright {
        text-align: right;
        padding-right: 8px;

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            text-align: center;
            padding-right: 0px;
        }
    }

    .lkcenter {
        text-align: center;
    }

    .bt_baixar_imagem {
        color: $cinza_textos;
        font-size: 12px;
        display: inline-block;
        position: relative;
        padding: 10px 0 10px 15px;
        @include transition1s;

        &::before {
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 12px;
            content: " ";
            background: url(../images/ico_download.png) no-repeat 0 -10px;
            @include transition2s;
        }

        &:hover {
            color: $azul_claro;

            &::before {
                background-position: 0 0px;
                @include transition2s;
            }
        }
    }

    .resumo_produto {
        h2 {
            font-size: 14px;
            color: $azul_escuro;
            display: block;
            padding: 12px;
            background-color: #ededed;
        }

        h4 {
            font-size: 14px;
            color: $azul_escuro;
            display: block;
            padding: 30px 0 8px 12px;
            text-transform: uppercase;
        }

        p {
            font-size: 12px;
            color: $cinza_textos;
            display: block;
            padding: 0 0 0 12px;
        }
    }

    .social-bar {
        display: inline-block;
        padding: 10px 0;

        .share-bar {
            width: 160px;
        }
    }

    .tabela_tecnica {
        width: 100%;
        margin-top: 40px;

        th,
        td {
            text-align: center;
            font-size: 12px;
            padding: 10px;
            color: $cinza_textos;
        }

        td {
            background-color: #d9d9d9;
        }

        td.even {
            color: $blank;
        }

        .table_colorJS {
            background-color: #d9d9d9;
        }

        tr {
            border-top: 1px solid #fff;

            th {
                background-color: $blank;
                font-size: 12px;
                color: $azul_escuro;
                text-transform: uppercase;
            }
        }

        .bt_download_ficha {
            color: $blank;
            font-size: 12px;
            display: inline-block;
            position: relative;
            padding: 0 0 0 15px;
            background-color: $azul_escuro;
            @include transition1s;

            &::before {
                display: block;
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0;
                top: 2px;
                content: " ";
                background: url(../images/ico_download_blank.png) no-repeat 0 -10px;
                @include transition2s;
            }

            &:hover {
                color: $azul_claro;

                &::before {
                    background-position: 0 0px;
                    @include transition2s;
                }
            }
        }
    }

    @media only screen and (max-width: $shelves-tablet-breakpoint) {

        /* Force table to not be like tables anymore */
        #no-more-tables table,
        #no-more-tables thead,
        #no-more-tables tbody,
        #no-more-tables th,
        #no-more-tables td,
        #no-more-tables tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        #no-more-tables thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        #no-more-tables tr {
            border: 1px solid #ccc;
            margin-bottom: 8px;
        }

        #no-more-tables td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
            white-space: normal;
            text-align: left;
            color: #666 !important;

            .bt_download_ficha {
                padding: 8px 8px 8px 30px;

                &::before {
                    top: 10px;
                    left: 8px;
                }
            }
        }

        #no-more-tables td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 10px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            text-align: left;
            font-weight: bold;
        }

        #no-more-tables td.even,
        #no-more-tables td.even:before,
        #no-more-tables td.color_bg_azul,
        #no-more-tables td.color_bg_azul:before {
            color: #fff !important;
        }

        #no-more-tables td.color_bg_azul::before {
            top: 18px;
        }

        /*
        Label the data
        */
        #no-more-tables td:before {
            content: attr(data-title);
        }
    }

    .videos_relacionados {
        margin-top: 30px;

        h5 {
            font-size: 24px;
            display: block;
            overflow: hidden;
            position: relative;
            padding: 20px 0;
            text-transform: uppercase;

            span {
                display: inline-block;
                padding: 0 20px;
                position: relative;
                background-color: #fff;
            }

            &::before {
                display: block;
                width: 100%;
                height: 1px;
                position: absolute;
                left: 0;
                top: 50%;
                content: " ";
                background: #d9d9d9;
            }
        }

        .videoBG {
            background-color: #ebebeb;

            .video_produto {
                padding: 10px;

                .LinkListagem {
                    position: relative;
                    display: block;
                    overflow: hidden;

                    @media only screen and (max-width: $shelves-mobile-breakpoint) {
                        margin-bottom: 15px;
                    }

                    .layerPlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.3);
                        background: rgba(0, 0, 0, 0.3);
                        background-image: url(../images/btPlay_peq.png);
                        background-repeat: no-repeat;
                        background-position: center center;
                        z-index: 10;
                    }

                    img {
                        position: relative;
                        display: block;
                        @include transition7s;
                        z-index: 1;

                        @media only screen and (max-width: $shelves-mobile-breakpoint) {
                            position: relative;
                            width: 100%;
                        }
                    }

                    &:hover {
                        img {
                            @media only screen and (min-width: $shelves-tablet-breakpoint) {
                                -webkit-transform: scale(1.25);
                                -moz-transform: scale(1.25);
                                -ms-transform: scale(1.25);
                                -o-transform: scale(1.25);
                                transform: scale(1.25);
                            }
                        }
                    }
                }
            }

            .dados_video {
                padding: 10px;

                small {
                    color: $azul_claro;
                    font-size: 12px;
                    display: block;
                    text-transform: uppercase;
                    padding-bottom: 10px;
                }

                p {
                    color: $azul_escuro;
                    font-size: 16px;
                    display: block;
                    text-transform: uppercase;
                    line-height: 19px;
                }
            }
        }
    }

    .detalhe_container {
        .slick-arrow {
            z-index: 999;
            top: unset;
            transform: rotate(90deg);
            position: unset;
            width: 100%;

            &::before {
                color: #252642;
            }
        }

        .slick-prev {
            left: unset;
            margin-bottom: 8px;
        }

        .slick-next {
            right: unset;
            margin-top: 8px;
        }

        .slick-slide:last-child {
            margin-bottom: unset;
        }

        
        @media only screen and (max-width: 1024px) {
            .slick-next {
                margin-bottom: 8px;
            }
        }

        @media only screen and (max-width: $shelves-mobile-breakpoint) {
            .slick-slider {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .slick-arrow {
                    width: unset;
                    position: unset;
                    transform: unset;
                }
    
                .slick-prev {
                    margin-bottom: unset;
                    padding-right: 5px;
                }
    
                .slick-next {
                    margin-bottom: unset;
                    margin-top: unset;
                    padding-right: 8px;
                }
            }
        }
    }
}

.paginacao-demo {
    display: block;
    width: 100%;
    clear: both;
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
    font-size: 0;

    .disabled {
        opacity: 0.5;
    }

    .active {
        background: #252642;

        a {
            color: #fff;
        }
    }

    li {
        display: inline-block;
        font-size: 12px;
        vertical-align: middle;
        color: #999999;
        line-height: 30px;
        display: inline-block;
        background: #f1f1f1;
        padding: 10px;
    }

    a {
        padding: 0 15px;
        display: block;
        color: #999999;
        font-weight: 700;
    }

    .large {
        font-size: 10px;
        background: #f2f2f2;
    }
}

.slider-for {
    .slick-slide {
        img {
            border: 1px solid #d7d7d7 !important;
        }
    }

    @media only screen and (min-width: $shelves-mobile-breakpoint) {
        padding: 0 8px;
    }
}

.slider-nav {
    @media only screen and (max-width: $shelves-mobile-breakpoint) {
        margin-top: 8px !important;
        margin-right: -8px;

        .slick-slide {
            border: 1px solid #d7d7d7 !important;
            margin-right: 8px !important;
            max-width: 62px;
        }
    }

    .slick-slide {
        margin-bottom: 8px !important;
        border: 1px solid #d7d7d7 !important;
    }
}

.slick-vertical {
    .slider-nav {
        .slick-slide {
            margin-bottom: 8px;
            border: 1px solid #d7d7d7 !important;

            img {
                width: 70px;
            }
        }
    }
}

.tablet-reset {
    @media only screen and (max-width: $shelves-tablet-breakpoint) {
        clear: both;
        float: none;
        width: 100%;
    }
}

.formClass {
    .erro {
        text-align: center;
        padding: 8px 0;
        display: block;
        color: #ff0003;
        font-size: 12px;
    }

    .sucesso {
        text-align: center;
        padding: 8px 0;
        display: block;
        color: #2dd40c;
        font-size: 12px;
    }

    label {
        color: #fff;
        font-size: 12px;
        display: block;
        padding-top: 10px;
        padding-bottom: 4px;
    }
}

#FormRow {
    .row {
        margin-top: 6px;
    }

    .column-4 {
        @media only screen and (min-width: $shelves-tablet-breakpoint) {
            @include column($n: 8, $total: 16, $gutter: 2%);

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .column-3-frm {
        @media only screen and (min-width: $shelves-tablet-breakpoint) {
            @include column($n: 3, $total: 9, $gutter: 2%);

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .spaceForm {
        margin-top: 30px;
    }

    textarea {
        height: 130px;
        margin-bottom: 30px;
        width: 100%;
        resize: none;
        border: 1px solid #999;
        border-left: 2px solid #00b4cd;
    }

    form .checkbox {
        visibility: hidden;
        padding: 0px;
        margin: 0;
        position: absolute;
    }

    label {
        font-size: 12px;
        color: $azul_escuro;
        display: block;
        text-transform: uppercase;
        padding: 15px 0 10px;
    }

    label.text-bold {
        font-size: 12px;
        color: $azul_escuro;
        font-weight: bold;
        display: block;
        text-transform: uppercase;
        padding: 5px 0 10px 0;
    }

    .requiredText {
        color: #666;
        font-size: 11px;
        margin-top: 20px;
        display: block;
    }

    .alignR {
        text-align: right;
    }

    .relativeSelect {
        position: relative;
        width: 100%;
    }

    .select-modify {
        height: 30px;
        position: relative;
        text-align: left;
        width: 100%;
        z-index: 2;
        cursor: pointer;
        @include opacity00;

        &:focus {
            outline: none;
        }
    }

    .selectSpan {
        background: $blank url("../images/selectcads.png") no-repeat scroll 98% center;
        border: none;
        bottom: 0;
        cursor: default;
        float: left;
        height: 30px;
        left: 0;
        line-height: 30px;
        position: absolute;
        text-align: left;
        text-indent: 10px;
        width: 100%;
        z-index: 1;
        cursor: pointer;
        border: 1px solid #999;
        border-left: 2px solid #00b4cd;
        font-size: 12px;
        color: #7d7d7d;
    }

    input {
        display: block;

        &[type='text'],
        &[type='email'],
        &[type='mail'],
        &[type='mask'],
        &[type='password'] {
            background: $blank;
            border: 1px solid #999;
            border-left: 2px solid #00b4cd;
            font-size: 12px;
            color: #7d7d7d;
            padding: 5px;
            width: 100%;
            outline: none !important;
            height: 30px;
            line-height: 30px;
        }

        &[type='submit'],
        &[type='buttom'] {
            border: none;
            width: 100%;
            height: 40px;
            line-height: 40px;
            background: #252642;
            position: relative;
            font-size: 18px;
            color: $blank;
            cursor: pointer;
            outline: none !important;
            text-transform: uppercase;
            text-align: center;
            @include transition2s;

            &:hover {
                background: #00b4cd;
            }

            @media only screen and (max-width: $shelves-tablet-breakpoint) {
                margin-top: 30px;
            }
        }

        &[readonly] {
            background-color: #f6f6f6;
            cursor: no-drop;
        }
    }

    label.labcheck[for] {
        position: relative;
        padding: 0 0 0 20px;
        cursor: pointer;
        text-transform: none;
    }

    label.labcheck[for]:before {
        content: '';
        position: absolute;
        border: 1px solid #999;
        background: #fff;
        width: 12px;
        height: 12px;
        top: 0px;
        left: 0px;
    }

    label.labcheck[for]:after {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;
        content: '';
        position: absolute;
        width: 14px;
        height: 7px;
        background-color: transparent;
        top: 0px;
        left: 0px;
        border-left: 3px solid #252642;
        border-bottom: 2px solid #252642;
        border-top: none;
        border-right: none;
        -webkit-transform: rotate(-55deg);
        -moz-transform: rotate(-55deg);
        -o-transform: rotate(-55deg);
        -ms-transform: rotate(-55deg);
        transform: rotate(-55deg);
    }

    .checkbox:checked+label.labcheck:after {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        opacity: 1;
    }

    .g-recaptcha {
        text-align: center;

        >div {
            display: inline-block;
        }
    }

    .padCaptcha {
        padding-top: 10px;
        padding-bottom: 10px;

        input {

            &[type='submit'],
            &[type='buttom'] {
                @media only screen and (min-width: $shelves-tablet-breakpoint) {
                    margin-top: 15px;
                }
            }
        }
    }

    .instruct_form {
        color: $azul_claro;
        display: block;
        font-size: 13px;
        font-weight: 400;
        padding: 20px 0 10px;
        text-align: left;
        text-transform: uppercase;
        width: 100%;

        strong {
            font-weight: 700;
        }

        a {
            color: $azul_claro;
            text-decoration: underline;
        }
    }
}

.introHeader {
    text-align: center;

    span {
        color: $cinza_textos;
        font-size: 12px;

        strong {
            color: $azul_claro;
            font-weight: 600;
        }
    }
}

.replist {
    padding: 50px 0 30px;
    overflow: hidden;
    margin-right: -5%;

    @media only screen and (min-width: $shelves-mobile-breakpoint) {
        margin-right: 0;
    }

    &::before {
        content: " ";
        display: block;
        clear: both;
    }

    li {
        margin-bottom: 8px;
        width: 45%;
        margin-right: 5%;
        text-align: left;
        display: block;
        float: left;

        @media only screen and (max-width: $shelves-tablet-breakpoint) {
            width: 100%;
            margin-right: 0%;
        }

        .bordazul {
            border-left: 2px solid $azul_claro;
            padding-left: 10px;
            margin-bottom: 10px;

            h6 {
                display: block;
                color: $azul_escuro;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 600;
                padding: 2px 0 4px 0;
            }

            small {
                display: block;
                color: $cinza_textos;
                font-size: 12px;
                line-height: 14px;

                a {
                    color: $cinza_textos;
                }
            }
        }

        hr {
            border: none;
            height: 1px;
            background-color: #d5d5d5;
            width: 80%;
            display: inline-block;
        }
    }
}

.selopre {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 0px 15%;
}

.bt_novaconsulta {
    display: inline-block;
    border: none;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #252642;
    position: relative;
    font-size: 18px;
    color: $blank;
    cursor: pointer;
    outline: none !important;
    text-transform: uppercase;
    text-align: center;
    @include transition2s;

    &:hover {
        background: #00b4cd;
    }

    @media only screen and (max-width: $shelves-tablet-breakpoint) {
        margin-top: 30px;
    }
}

.banner_cad_lojista {
    width: 100%;
    overflow: hidden;
    padding: 20px 0;

    @media only screen and (max-width: $shelves-tablet-breakpoint) {
        margin: 10px 0;
    }

    img {
        min-width: 943px;
        min-height: 210px;

        @media only screen and (max-width: $shelves-tablet-breakpoint) {
            min-width: 700px;
            min-height: 156px;
        }
    }
}

.aviso_boleto {
    display: none;
}

.instrucao_boleto {
    display: none;
}

#login_lojista input {
    border-left: 2px solid #a4d9ec;
}

#login_lojista,
#cadastro_lojista,
#page_representante {

    .cml_error_class_cads,
    .cml_error_class_cads:focus {
        border-left: 2px solid #d90101 !important;

        span.selectSpan {
            border-left: 2px solid #d90101 !important;
        }
    }
}

.cml_error_class_select,
.cml_error_class_select:focus {
    span.selectSpan {
        border-left: 2px solid #d90101 !important;
    }
}

#page_contato {

    .cml_error_class_cads,
    .cml_error_class_cads:focus {
        input {

            &[type='text'],
            &[type='email'],
            &[type='password'],
            &[type='mask'] {
                border-left: 2px solid #d90101 !important;
            }
        }

        textarea {
            border-left: 2px solid #d90101 !important;
        }

        span.selectSpan {
            border-left: 2px solid #d90101 !important;
        }
    }

    .contato_receber_news {
        margin-bottom: 10px;
    }   

    .label_privacy_policy {
        max-width: 600px;
        
        a { 
            text-decoration: underline;
        }

        &.label_privacy_policy_error {
            color: #ff0000;

            a {
                color: #ff0000;
            }
        }
    }    
}

.hide {
    display: none !important;
}

.inner_padding {
    position: relative;

    .row_timeline {
        position: relative;
        padding-bottom: 70px;
        margin-bottom: 20px;

        .itemBolota {
            padding: 10px 0;
            position: relative;
            overflow: hidden;

            .column-6 {
                @media only screen and (min-width: $shelves-tablet-breakpoint) {
                    @include column($n: 5, $total: 10, $gutter: 0%);

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .data_timeline {
                font-size: 72px;
                color: $azul_escuro;
                font-family: $opensans-light;

                &.left {
                    padding-left: 38px;
                    text-align: left;
                }

                &.right {
                    padding-right: 38px;
                    text-align: right;
                }

                @media only screen and (max-width: $shelves-tablet-breakpoint) {
                    &.right {
                        padding-right: 10px;
                        padding-left: 38px;
                        text-align: left;
                    }
                }
            }

            .desc_timeline {
                font-size: 14px;
                color: $azul_escuro;
                font-family: $opensans;
                padding-top: 10px;
                line-height: 16px;

                &.left {
                    padding-left: 38px;
                    text-align: left;
                }

                &.right {
                    padding-right: 38px;
                    text-align: right;
                }

                @media only screen and (max-width: $shelves-tablet-breakpoint) {
                    &.right {
                        padding-right: 10px;
                        padding-left: 38px;
                        text-align: left;
                    }

                    br {
                        display: none;

                        &.block {
                            display: block;
                            clear: both;
                        }
                    }
                }
            }

            .pusha-4 {
                left: 50%;
            }

            .pulla-4 {
                right: 50%;
            }

            &::after {
                position: absolute;
                background: url("../images/historico_ball.png") no-repeat 0 0;
                width: 28px;
                height: 28px;
                left: 50%;
                margin-left: -14px;
                top: 20px;
                display: block;
                content: " ";

                @media only screen and (max-width: $shelves-tablet-breakpoint) {
                    left: 0%;
                    margin-left: 0px;
                }
            }
        }

        &::before {
            position: absolute;
            background: #959595 url("../images/dash.png") no-repeat 0 bottom;
            width: 2px;
            height: 100%;
            left: 50%;
            margin-left: -1px;
            top: 20px;
            display: block;
            content: " ";

            @media only screen and (max-width: $shelves-tablet-breakpoint) {
                left: 14px;
                margin-left: 0px;
            }
        }
    }
}

.inline {
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 350px;
    padding: 0;
    width: 100%;
    margin: 0 auto;
}

.center {
    text-align: center;
    margin-top: 20px;
}

figcaption img {
    max-width: 100%;
}

#detalhe-canal .decDestalhe figcaption {
    margin-top: 17;
}

.documentos {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 25px;

    .tabela_tecnica {
        margin-top: 10px;

        td {
            background-color: #252642;
            border-bottom: 1px solid #d9d9d9;
            text-align: center;
        }

        td.even {
            background-color: transparent !important;
            color: #666666;
            text-align: left;
        }
    }

    h5 {
        position: relative;
        font-size: 25px;
        background: #fff;
        padding-right: 50px;

        &::before {
            content: '';
            height: 1px;
            width: 70%;
            background-color: #d9d9d9;
            position: absolute;
            right: 0px;
            top: 12px;
        }
    }
}

//FIQUE POR DENTRO
.fique-por-dentro {
    position: relative;
    float: left;
    width: 100%;

    .topo {
        background: url("../images/fiquepordentro/banner.jpg") no-repeat scroll center top;
        position: relative;
        float: left;
        width: 100%;
        min-height: 740px;
    }

    .box-texto {
        float: right;
        position: relative;
        right: 0px;
        color: #252642;
        text-align: right;
        top: 98px;

        .title {
            font-weight: 800;
            font-size: 48px;
            text-transform: uppercase;
            line-height: 55px;
        }

        .desc {
            font-size: 12px;
            line-height: 18px;
            margin: 25px 0px;
            padding-left: 138px;
        }
    }

    .center {
        width: 100%;
        text-align: center;
        display: block;
    }

    .btn-carregar-mais {
        background: #252642;
        color: #fff;
        padding: 18px 70px;
        font-size: 18px;
        font-weight: 400;
        border-radius: 10px;
        margin-top: 0px;
        position: relative;
        display: inline-block;
        margin-bottom: 15px;
        margin-top: 50px;
    }

    .btn-youtube {
        background: #db2b28;
        color: #fff;
        padding: 18px 29px;
        font-size: 18px;
        font-weight: 400;
        border-radius: 10px;
        margin-top: 0px;
        position: relative;
        display: inline-block;
        margin-bottom: 15px;
    }

    .conteudo-videos {
        background: #ebebeb;
        position: relative;
        float: left;
        width: 100%;
        margin-top: 50px;
        padding: 50px 0px;

        .column-4 {
            padding: 0 5px;
            height: 295px;
            margin-bottom: 10px;
        }

        .bg-white {
            background: #fff;
            padding: 15px;
            float: left;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .box-titulo {
            font-size: 16px;
            color: #252642;
            float: left;
            position: relative;
            width: 100%;
            margin-top: 10px;
            line-height: 20px;
        }

        .box-imagem {
            position: relative;
            float: left;
            width: 100%;

            .time {
                color: #fff;
                font-size: 12px;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 5px;
                position: absolute;
                right: 5px;
                bottom: 5px;
                padding: 4px 6px;
            }
        }
    }

    .lista-videos {
        position: relative;
        float: left;
        width: 100%;
    }
}

#menuToggle {
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    z-index: 1;
    user-select: none;
    right: 0;
}

#menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */

    -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
    position: fixed;
    margin: 0;
    padding: 0;
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(0%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    left: 0;
    height: 100%;
    width: 200px;
    min-width: 200px;
    min-height: 100vh;
    float: left;
    z-index: 9999;
}

#menu li {
    padding: 10px 0;
    font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked~ul {
    transform: none;
}

.menu-mobile-topo {
    display: none;
}

.hamburger {
    position: absolute;
    right: 0;
    top: 4px;
    z-index: 9;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    background: #fff;
    width: 28px;
    height: 2px;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background: #fff;
}

.menu-topo-mobile {
    height: 100%;
    @media only screen and (max-width: 1169px) {
        height: auto;
    }

    .cd-primary-nav {
        width: 240px;
        visibility: visible;
        height: 100%;
        min-height: 100vh;
        overflow-y: auto;
        left: -400px;
        @include transition3s;
    }

    .submenu-exband {
        a {
            padding: 7px 20px;
        }
    }

    .categorias-sub-menu {
        a {
            padding: 0px 20px;
        }
    }

    .trigger-menu{
       position: absolute;
       right: 12px;
       top: 22px;
       width: 15px;
       height: 15px;
       background: #00afef;
       color: #fff;
       display: flex;
       align-items: center;
       justify-content: center;
    }
}

.display-nav {
    overflow: hidden;

    .menu-topo-mobile {
        .cd-primary-nav {
            left: 0;
        }
    }
}

.menu-produtos {
    a {
        border-bottom: 0;
        margin-bottom: 7px;
    }
}

.submenu-expand {
    display: block;
    position: relative !important;
    width: 100% !important;
    border-bottom: 1px solid #00afef;
    padding-bottom: 0px;
    margin-top: -10px;
    height: 0 !important;
    overflow: hidden !important;

    li {
        position: relative;
        float: left;
        width: 100%;
        background: #EDEDED !important;
        a {
            padding-left: 22px;
            border-bottom: 0;
            line-height: 25px;
            font-size: 15px;
            height: auto;
            color: #6D6D6D !important;
            margin-bottom: 6px;
            padding-top: 10px;
            
        }
    }

    ul {
        position: relative !important;
        width: 100% !important;
        float: left;

        li {
            a {
                padding-left: 25px;
                border-bottom: 0;
                line-height: 25px;
                height: auto;
                font-size: 13px;
                padding-top: 0px;
                border-bottom: 0px solid #CCCCCC;
            }
        }
    }
    .trigger-accordeon {
        color: transparent;
        display: block;
        height: 13px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 13px;
        z-index: 50;
        right: 14px;
        cursor: pointer;
        background: url(../images/accordeon.png) no-repeat 0 0;
        top: 50%;
        margin-top: -2px;
        transform: rotate(0deg);
        
    }
    .fa-open-show{
        transform: rotate(180deg);
    }
}

.categorias-sub-menu{
    background: #EDEDED !important;
    display: none;
    border-bottom: 1px solid #CCCCCC;
    li{
        a{
            color: #6D6D6D !important;
        }
    }
}

.collapsed-submenu{
    display: block;
}

.aberto-submenu {
    height: 100% !important;
}

.link-mobile {
    display: none !important;
}

.submenu-expand {
    display: none !important;
}

.line-height-time-line {
    line-height: 35px;

    &.left {
        span {
            padding-left: 0;
            padding-right: 80px;

            &::after {
                left: auto;
                right: 50%;
                margin-left: 0;
                margin-right: 146px;
            }
        }
    }

    span {
        margin-bottom: 30px;
        display: block;
        width: 100%;
        position: relative;
        padding-left: 80px;

        &::after {
            position: absolute;
            background: url(../images/historico_ball.png) no-repeat 0 0;
            width: 28px;
            height: 28px;
            left: 50%;
            margin-left: 146px;
            top: 50%;
            margin-top: -16px;
            display: block;
            content: " ";
        }
    }

    span.sem-bolota {
        &::after {
            background: none;
        }
    }
}

.int {
    float: left;
    width: 100%;
    position: relative;

    .column-9 {
        margin-top: 30px;
        position: relative;
        float: left;
        width: 100%;
    }

    .box-desc {
        position: relative;
        width: 100%;
        float: left;
    }

    .box-imagem {
        text-align: center;
    }
}

.politica-de-uso {
    h2 {
        border-left: 0;
        float: left;
        width: 100%;
        position: relative;
        text-transform: uppercase;
        padding: 20px;
        background-color: #ebebeb;
        margin-top: 20px;
    }
    p {
      margin-bottom: 15px;
    }
}

.regulamento {
    .desc {
        text-align: left;
        margin-top: 30px;
        @include rfs(16);
        padding: 0 20px;
    }
}

.politica-apps {
    ul {
        margin-top: 35px;
        margin-bottom: 35px;
        li {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    margin-bottom: 60px;
}


//grid projeto
//Mobile
@include max-screen(1169px) {
    #produtos{
        .column-3{
            display: none;
        }
    }
    .line-height-time-line {
        span {
            padding-left: 0px;

            &::after {
                left: 0%;
                margin-left: -38px;
            }
        }
    }

    .link-mobile {
        display: block !important;
    }

    .submenu-expand {
        display: block !important;
    }

    .contRight {
        .menu_apoio {
            ul {
                text-align: center;
            }
        }
    }

    .cd-header-buttons {
        display: none;
    }

    .link-desktop {
        display: none !important;
    }

    .link-mobile {
        display: block;
    }

    .menu-mobile-topo {
        display: block;
    }

    .submenu-expand {
        display: block;
    }

    .produto_detalhe #no-more-tables td:before {
        content: attr(data-title);
    }

    .produto_detalhe {
        .column-1 {
            position: relative;
        }

        .slider-nav .slick-slide {
            max-width: 100% !important;
        }
    }

    .fique-por-dentro {
        .topo {
            min-height: 560px;
        }

        .btn-youtube {
            padding: 18px 12px;
            margin-right: 20px;
            margin-left: 20px;
        }

        .box-texto {
            text-align: center;
            top: 52px;

            .title {
                font-size: 42px;
                line-height: 50px;
            }

            .desc {
                padding-left: 20px;
                padding-right: 10px;
            }
        }

        .conteudo-videos {
            .column-4 {
                padding: 0 2px;
                height: auto;
                margin-bottom: 5px;
                width: 49%;
                float: none;
                display: inline-block;
            }

            .box-titulo {
                font-size: 12px;
                line-height: 17px;
                min-height: 60px;
            }
        }
    }
}

.fancybox-button--share {
    display: none !important;
}

.banner {
    margin: 0px;
    .rocket-flicker ul.flicks {
        height: 415px;
        margin-top: 0px;
        li {
            background-position: center;
        }
    }
    .rocket-flicker-arrow {
        top: 40%;
    }
    .rocket-flicker-arrow._left {
        left: 0%;
        margin-left: 0px;
    }
    .rocket-flicker-arrow._right {
        margin-right: 0px;
        margin-top: -1px;
        right: 0%;
        transform: rotate(-178deg);
    }
    .rocket-flicker-dots {
        position: absolute;
        bottom: 15px;
        width: 100%;
        text-align: center;
        z-index: 100;
    }
    .rocket-flicker-dots ul {
        display: inline-block;
        list-style: outside none none;
        padding: 2px 28px;
        position: relative;
        text-align: center;
    }
    .rocket-flicker-dots._right,
    .rocket-flicker-dots._right ul {
        text-align: right;
    }
    .rocket-flicker-dots .dot {
        background-color: #FFF;
    }
    .rocket-flicker-dots ul li {
        display: inline-block;
        float: none;
        margin-top: 12px;
    }
}

.catalogos-online {
    padding: 0 20px;

    .card {
        float: left;
        width: 25%;
        padding: 5px;
        flex-wrap: wrap;

        img {       
            width: 100%;
            object-fit: cover;        
            max-height: 268px;
            min-height: 268px;                        
            margin-bottom: 15px;
        }

        .box-texto {
            .box-data {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                font-size: 12px;

                .month {
                    text-transform: uppercase;
                    color: #00b4cd;                  
                }
            }

            .titulo-card {
                font-size: 16px;
                line-height: 20px;
                text-transform: uppercase;
                color: #252642;
                min-height: 40px;
                margin-bottom: 15px;
            }
            
            .desc {
                color: #252642;
                min-height: 60px;
                font-size: 12px;
                line-height: 16px;
            }                
        }    
        
        hr {
            height: 1px;
            background-color: #eaeaea;
            border: none;           
        }

        @media only screen and (max-width: 1023px) {
            width: 33.3%;                        

            img {  
                height: auto;
                max-height: unset;
                min-height: unset;
            }
        }

        @media only screen and (max-width: 425px) {
            width: 50%;

            img {  
                height: unset;
                max-height: 182px;
            }
        }

        @media only screen and (max-width: 375px) {
            width: 100%;

            img {  
                height: auto;
                max-height: unset;
            }
        }
    }

    .row::after {
        content: "";
        clear: both;
        display: table;
    }
}

.modal-cookies-preferences {
    position: fixed;
    z-index: 9999;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    display: none;
    max-width: 1000px;
    width: 100%;
    display: none;

    &.active {
        display: flex;
        flex-wrap: wrap;    
    }

    form {        
        .header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            background-color: #ebebeb;

            p {
                font-size: 18px;
                font-weight: bold;
                color: #3f4059;
            }

            .box-close {
                padding-right: 30px;

                .close {
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    background: url(../images/btn-close-cookies-preferences.png) no-repeat center center;
                    display: block;
                }
            }        
        }

        .content {
            display: flex;
            width: 100%;
            min-height: 340px;

            .tabs-nav {
                width: 40%;

                ul {
                    li {
                        display: flex;
                        color: #3f4059;    
                        font-size: 14px;
                        border-bottom: 1px solid #e5e5e5;   
        
                        .box-text {
                            cursor: pointer;
                            font-weight: bold;
                            width: 244px;
                            line-height: 1.2;
                            padding: 12px 20px;
            
                            &.active {
                                background-color: #00afef;                        
                                color: #ffffff;                         
                            }
                        }
        
                        .box-accepts {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 144px;      
                            padding: 0 10px;              
                            
                            &.necessary-cookies {
                                justify-content: flex-end;
                                font-weight: bold;
                                color: #00afef;
                            }
                            
                            .accept {                                                      
                                input {
                                    appearance: auto;
                                }
                            }
                        }                
                        
                        &:last-child {
                            border: none;
                            justify-content: center;
                            flex-direction: column;
                            padding: 5px 30px 30px 30px;

                            .message {
                                height: 25px;                            
                                color: #ffffff;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &.show {
                                    color: #ff3a3a;
                                    font-weight: bold;  
                                }
                            }

                            .box-buttons {
                                display: flex;
                                flex-direction: row;

                                button {
                                    border: none;
                                    width: 170px;
                                    font-weight: bold;
                                    font-size: 14px;
                                    padding: 10px 0;
                                    border-radius: 10px;
                                    cursor: pointer;
            
                                    &:first-child {
                                        margin-right: 4px;
                                    }
            
                                    &:last-child {
                                        margin-left: 4px;
                                    }
                                }
                            }                        
                        }
                    }            
                }
            }

            .tabs {
                width: 60%;
                padding: 25px 40px;

                .tab {
                    display: none;
                    font-size: 14px;
                    color: #666666;

                    &.active {
                        display: block;
                    }
                
                    h3 {
                        margin-bottom: 20px;
                        color: #42435b;
                        font-weight: bold;
                    }             
                    
                    p {
                        font-size: 14px;
                        margin-bottom: 20px;
                        line-height: 1.3;
                        text-align: justify;
                    }
                }
            }        
        }

        .footer {
            border-top: 1px solid #e5e5e5;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            padding: 20px 0;

            button {
                border: none;
                height: 40px;
                border-radius: 10px;
                background-color: #00afef;
                color: #ffffff;
                font-size: 14px;
                font-weight: bold;
                width: 220px;
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        transform: none;
        top: 0;
        left: 0;
        height: 100vh;
        overflow: auto;

        form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            .header {
                padding: 15px;

                img {
                    display: none;
                }

                .box-close {
                    padding: 0;
                }
            }

            .content {
                height: 100%;
                min-height: unset;
                flex-direction: column;

                .tabs-nav {
                    width: 100%;

                    ul {
                        li {
                            .box-text {
                                width: 100%;
                            }

                            .box-accepts {
                                width: 200px;
                            }

                            &:last-child {
                                padding: 5px 20px 20px 20px;

                                .box-buttons {
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }

                .tabs {
                    width: 100%;
                    padding: 0 20px;
                }
            }
        }
    }

    @media only screen and (max-width: 425px) { 
        form {
            .header {
                p {
                    font-size: 15px;
                    line-height: 1.2;
                }
            }

            .content { 
                .tabs-nav {
    
                    ul {
                        li {                                                      
                            &:last-child {                            
                                display: flex;
                                flex-direction: column;

                                .box-buttons {
                                    // flex-direction: column;

                                    button {
                                        width: 100%;
                                        margin-bottom: 10px;
        
                                        &:first-child {
                                            margin-right: 3px;
                                        }
                
                                        &:last-child {
                                            margin-left: 3px;
                                        }
                                    }
                                }                            
                            }
                        }            
                    }
                }
            }

            .footer {
                padding: 20px;

                button {
                    width: 100%;
                }
            }
        }
    }
}

.cookies-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    &:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0, .5);
        z-index: -1;
    }

    .container {
        background: #ebebeb;
        padding: 30px 0;
        width: 100%;

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1000px;
            width: 90%;
            margin: 0 auto;

            .text {
                color: #252642;
                max-width: 490px;

                h1 {
                    text-transform: unset;
                    color: #252642;
                    font-size: 24px;
                    font-weight: 700;
                    margin: 0 0 10px 0;
                    padding: 0;
                    text-align: unset;
                }

                p {
                    line-height: 16px;
                    font-size: 14px;
                }

                a {
                    color: #00afef;
                    text-decoration: underline;
                }
            }   
            
            .links {
                form {
                    font-size: 14px;

                    a {
                        color: #00afef;
                        text-decoration: underline;
                    }

                    .cookies-accept {
                        width: 210px;
                        display: inline-block;
                        background: #00afef;
                        color: #fff;
                        text-align: center;
                        padding: 8px 0px;
                        border-radius: 8px;
                        font-weight: 700;
                        margin-left: 20px;
                        border: none;
                        cursor: pointer;
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                .links { 
                    form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        a {
                            margin-bottom: 10px;
                        }

                        .cookies-accept {
                            margin-left: unset;
                        }
                    }
                }
            }

            @media only screen and (max-width: 425px) {
                flex-direction: column;
            
                .text {
                    margin-bottom: 20px;

                    h1 {
                        font-size: 22px;
                    }

                    p {
                        line-height: 15px;
                        font-size: 13px;
                    }
                }   
            }
        }
    }
}

.alert-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 15px;
    border-radius: 4px;
    font-size: 14px;
    
    ul {
        list-style: none;

        li {
            margin-bottom: 5px;
        }
    }
}

.box-recaptcha {
    clear: both; 
    display: flex; 
    margin: 10px 0;
    align-items: center;

    .captcha {
        margin-right: 10px;
    }
}

/* Estilo para a modal */
.modal {
    position: fixed;
    display: flex; /* Por padrão, esconde a modal */
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
    z-index: 9999; /* Garante que a modal fique sobre todo o conteúdo */
    overflow: auto; /* Adiciona rolagem se o conteúdo for maior que a tela */
}

/* Estilo para o conteúdo da modal */
.modal .content {
    color: #252642;
    background-color: #fff; /* Cor de fundo branco */
    padding: 25px;
    border-radius: 5px;
    max-width: 500px;
    margin: 100px auto; /* Centraliza vertical e horizontalmente */

    h3 {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 800;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
    }
}

/* Estilo para o botão de fechar */
.modal .actions {
    margin-top: 20px;
    text-align: center;
}

/* Estilo para o botão de fechar */
.modal #button-modal {
    background-color: #252642; /* Cor de fundo azul */
    color: #fff; /* Cor do texto branco */
    border: none;
    padding: 10px 60px;
    cursor: pointer;
    border-radius: 5px;
}

/* Estilo para o botão de fechar quando hover */
.modal #button-modal:hover {
    background-color: #0056b3; /* Cor de fundo azul escuro */
}

@import "menu.css";