@mixin box-sizing {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@mixin hide-text {
  white-space: nowrap;
  text-indent: -9999px;
  overflow:hidden;
  display: block;
}

@mixin opacity01 {
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
}

@mixin opacity08 {
    opacity: 0.8;
    -webkit-opacity: 0.8;
    -moz-opacity: 0.8;
    -ms-opacity: 0.8;
    -o-opacity: 0.8;
}
@mixin opacity07 {
    opacity: 0.7;
    -webkit-opacity: 0.7;
    -moz-opacity: 0.7;
    -ms-opacity: 0.7;
    -o-opacity: 0.7;
}
@mixin opacity00 {
    opacity: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
}

@mixin transition7s {
    transition: all 0.7s ease-in-out 0s;
    -webkit-transition: all 0.7s ease-in-out 0s;
    -moz-transition: all 0.7s ease-in-out 0s;
    -ms-transition: all 0.7s ease-in-out 0s;
    -o-transition: all 0.7s ease-in-out 0s;
}
@mixin transition5s {
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
}
@mixin transition3s {
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
}
@mixin transition2s {
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}
@mixin transition1s {
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
}
@mixin grayscale {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

@mixin scale09 {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}

@mixin underlineAnimate{
    &::after{
      content:" ";
      width:1px;
      height:1px;
      display:block;
      @include opacity00();
      @include transition3s();
      background-color:#7b7b7b;
      position:absolute;
      left:0;
      bottom:0;
    }
    &:hover{
      &::after{
        width:100%;
        @include opacity01();
      }
    }
}



@mixin select-modify{
    height: 23px;
    position: relative;
    text-align: left;
    width: 100%;
    z-index: 2;
}
@mixin selectSpan{
    background: url("../images/select.png") no-repeat scroll 98% 5px #fff;
    border: none;
    bottom: 0;
    color: #666666;
    cursor: default;
    float: left;
    font-size: 12px;
    height: 23px;
    left: 0;
    line-height: 24px;
    position: absolute;
    text-align: left;
    text-indent: 10px;
    width: 100%;
    z-index: 1;

}


